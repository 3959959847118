import React, {useState} from 'react';
import s from './style.module.css'
import PinInput from '../../../../shared/UI/PinInput/PinInput';
import {useDispatch, useSelector} from "react-redux";
import {selectViewerPhone, sendSmsToViewer} from "../../../../entities/Viewer/Model/viewer";
import {viewerAPI} from "../../../../shared/api/api";
import ReSendSmsButton from "../../../../shared/UI/reSendSmsButton/ReSendSmsButton";

const LoginWithSmsCodeChecker = ({closePopup}) => {

    const dispatch = useDispatch()
    const initDigits = ['', '', '', ''];
    const [digits, setDigits] = useState(initDigits);
    const viewerPhone = useSelector(selectViewerPhone)

    const formData = {
        phone: viewerPhone,
        code: digits.join(''),
        recaptcha_resp: '',
    }

    const formSubmitHandler = async (e) => {
        e.preventDefault()
        try {
            const response = await viewerAPI.loginViewerWithPin(formData)
            switch (response.data.message){
                case 'logged in':
                    closePopup()
                    location.reload()
                    break;
                case 'invalid pin':
                    alert('Неверный код')
                    break;
            }
        } catch (e){
            document.getElementById('reactAuthLogChecker').classList.add('hide')
            alert('Извините, произошла ошибка!')
            console.log(e)
        }
    }
    
    function getCookie(name) 
    {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    return (
        <div className={s.codeChecker__container}>
            <div className={s.codeChecker__text}>
                Введите последние 4 цифры входящего номера
            </div>
            <form className={s.codeChecker__formContainer} onSubmit={formSubmitHandler}>
                <div className={s.codeChecker__pinWrap}>
                    <div className={s.pinInput__container}>
                        <PinInput digits={digits} changeHandler={setDigits} />
                    </div>
                </div>
                
                <div className={s.reSendBtn__Wrap}>
                    <ReSendSmsButton
                        seconds={30}
                        reSendDisabledBtnText={'Отправить SMS с кодом через '}
                        reSendActiveBtnText={'Отправить SMS'}
                        reSendFunc={()=>{
                            var recaptchaResp = getCookie('recaptcha_resp');
                            if (recaptchaResp == "undefined")
                            {
                                alert('Истекло время проверки на робота. Пожалуйста, пройдите проверку и попробуйте снова.');
                                location.reload();
                            }
                            else
                            {
                                formData.recaptcha_resp = recaptchaResp;
                                dispatch(sendSmsToViewer(formData))
                            }
                        }}
                    />
                </div>
                <div className={s.textInfo}>
                    Продолжая, вы соглашаетесь <a href='/about/personal-data-policy/' className={s.textInfoLink}>со сбором и обработкой персональных данных и пользовательским соглашением. </a>
                </div>
            </form>
        </div>
    );
};

export default LoginWithSmsCodeChecker;