import React, { useRef, useEffect } from 'react';
import s from './style.module.css'

export default function PinCodeInput(props) {
    const {
      onSubmit,
        digits,
        changeHandler,
    } = props;
    const length = digits.length;
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            event.preventDefault();
            if (digits[index].match(/^[0-9]$/)) {
                const newDigits = [...digits];
                newDigits[index] = '';
                changeHandler(newDigits);
            } else {
                if (index > 0) inputRefs.current[index - 1].focus();
            }
        }

    }

    const handleChange = (index, newValue) => {
        const oldDigit = digits[index];
        const newDigit = newValue.trim().replace(oldDigit, '');
        if (newDigit < '0' || newDigit > '9') return;
        const newDigits = [...digits];
        newDigits[index] = newDigit;
        changeHandler(newDigits);
        if (index < length - 1) {
            inputRefs.current[index + 1].focus();
        } else {
          setTimeout(() => {inputRefs.current[index].form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}, 0)
        }
    }

    return (
        <div className={s.pinCode__container}>
            {digits.map((digit, index) => (
                <input
                    key={index}
                    className={s.pinCode__input}
                    value={digit}
                    onChange={event => handleChange(index, event.target.value)}
                    onKeyDown={event => handleKeyDown(index, event)}
                    ref={element => inputRefs.current[index] = element}
                    inputMode="numeric"
                />
            ))}
        </div>
    );
}