import React, {useState} from 'react';
import s from './styles.module.css'
import CloseButton from "../../shared/UI/CloseButton/CloseButton";
import LoginWithSMS from "../../features/auth-form/login/LoginWithSMS/LoginWithSMS";
import LoginWithSMSCodeChecker from "../../features/auth-form/login/LoginWithSMSCodeChecker/LoginWithSMSCodeChecker";
import clsx from "clsx";

const AuthForm = ({closeAuthForm}) => {
    const [currentAuthModal, setCurrentAuthModal] = useState('Вход')
    const popupContainer = clsx(s.authForm__popupContainer, {
        [s.authForm__popupContainerTight]: currentAuthModal === 'Вход'
                                        || currentAuthModal === 'Номер ещё не зарегистрирован'
                                        || currentAuthModal === 'Создать новый пароль',
        [s.authForm__popupContainerRgb]: currentAuthModal === 'Номер ещё не зарегистрирован'
    })

    const closeAuthPopup = () => {
        closeAuthForm()
        setCurrentAuthModal('Вход')
    }

    const changeAuthModelHandler = (newModal) => {
        setCurrentAuthModal(newModal)
    }

    const currentModal = {
        'Вход'                         : LoginWithSMS,
        'Проверка входа'               : LoginWithSMSCodeChecker, 
    }

    const CurrentModalComponent = currentModal[currentAuthModal]

    return (
        <div className={s.authForm__wrap}>
            <div className={popupContainer}>


                <div className={s.authForm__title}>
                    <p>Вход / регистрация</p>
                    <CloseButton
                        onCloseButtonClicked={closeAuthPopup}
                    />
                </div>

                <CurrentModalComponent
                    changeModalType={changeAuthModelHandler}
                    closePopup={closeAuthPopup}
                />

            </div>
        </div>
    );
};

export default AuthForm;